import * as React from "react";
import { Button, Container, Form, FormLabel } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import Layout from "../components/Layout";
export default function ForgotPasswordPage() {
  const handleSubmit = (e) => {
    var emailvalue = document.getElementById("emailvalue")?.value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: emailvalue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/ForgotPasswordWeb`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          document.querySelector(".emailsent").style.display = "block";
          document.querySelector(".emailsenterror").style.display = "none";
        } else {
          document.querySelector(".emailsenterror").style.display = "block";
          document.querySelector(".emailsent").style.display = "none";
        }
        return response?.text() ?? "";
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fx-blod mb-5">Forgot Password</h1>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <form className="text-start my-5" onSubmit={handleSubmit}>
                <p
                  className="emailsent"
                  style={{ display: "none", fontSize: "11px" }}
                >
                  An email has ben sent to your account
                </p>
                <p
                  className="emailsenterror"
                  style={{ display: "none", fontSize: "11px", color: "red" }}
                >
                  Email not found.
                </p>

                <Form.Group className="mb-3">
                  <FormLabel className="fs-13 bogo-par">
                    E-mail address
                  </FormLabel>
                  <Form.Control
                    type="email"
                    placeholder="E-mail address"
                    id="emailvalue"
                    required
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  className="w-100 fs-14 h-52"
                  type="submit"
                >
                  Change Password
                </Button>
              </form>
              <p className="mb-0">
                Please click the link in your inbox to continue
              </p>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
